(function () {
  'use strict'

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})(),

$('.variable-width').slick({
  dots: false,
  infinite: true,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  appendArrows:$('.arrows'),
  responsive:[
    {
      breakpoint:1400,
      settings: {
        centerPadding:'0'
      }
    },
    {
      breakpoint:576,
      settings: {
        centerMode: false,
        variableWidth: false
      }

    }
  ]
});

$('.multiple-items').slick({
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  appendArrows:$('.arrows2'),
  responsive:[
    {
      breakpoint:576,
      settings: {
        slidesToShow: 1
      }

    }
  ]
});


$("body").on("focusin", "input[type=tel]", (function() {
    $(this).inputmask({
        mask: ["+79999999999", "+799999999999"],
        greedy: !1
    })
}
));






$(document).ready(function() {
	$('input, input[type="text"], input[type="number"], input[type="email"], input[type="submit"], input[type="file"], textarea, button, select').styler();

	$("#mobile_menu").click(function() {
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			$("#navigation").removeClass('visible');
			$("#navigation").animateCss('visible animated fadeOutLeft');
		} else {
			$(this).addClass('active');
			$("#navigation").addClass('visible');
			$("#navigation").animateCss('animated fadeInLeft');
		}
		return false;
	});

	$('.ring_link').click(function() {
		$("#pop_ring_form").addClass('visible');
		$("#pop_ring_form").animateCss('animated fadeIn');
		return false;
	});
	$('.feedback_link').click(function() {
		$("#pop_feedback_form").addClass('visible');
		$("#pop_feedback_form").animateCss('animated fadeIn');
		return false;
	});

	$('.open_pop_model').click(function() {
		$("#pop_model").find('iframe').attr('src', $(this).attr('data-url'));
		$("#pop_model").addClass('visible');
		$("#pop_model").animateCss('animated fadeIn');
		return false;
	});

	$('.open_pop_info').click(function() {
		$($(this).attr('href')).addClass('visible');
		$($(this).attr('href')).animateCss('animated fadeIn');
		return false;
	});

	var old_title = 'Отправить заявку';
	$('.order_link').click(function() {

		attr = $(this).attr('data-name');
		attr_title = $(this).attr('data-title');
		if (typeof attr !== typeof undefined && attr !== false) {
			$('#pop_order_data_name').val(attr);
		}
		if (typeof attr_title !== typeof undefined && attr_title !== false) {
			old_title = $('#pop_order_form title_h2').html();
			$('#pop_order_form .title_h2').html('<span class="small">'+attr_title+'</span>');
		}
		attr = $(this).attr('data-id');
		if (typeof attr !== typeof undefined && attr !== false) {
			$('#pop_order_data_id').val(attr);
		}
		$("#pop_order_form").addClass('visible');
		$("#pop_order_form").animateCss('animated fadeIn');
		return false;
	});

	$('.msrp_link').click(function() {

		attr = $(this).attr('data-name');
		attr_title = $(this).attr('data-title');
		if (typeof attr !== typeof undefined && attr !== false) {
			$('#pop_msrp_data_name').val(attr);
		}
		if (typeof attr_title !== typeof undefined && attr_title !== false) {
			old_title = $('#pop_order_form title_h2').html();
			$('#pop_msrp_form .title_h2').html('<span class="small">'+attr_title+'</span>');
		}
		attr = $(this).attr('data-id');
		if (typeof attr !== typeof undefined && attr !== false) {
			$('#pop_msrp_data_id').val(attr);
		}
		$("#pop_msrp_form").addClass('visible');
		$("#pop_msrp_form").animateCss('animated fadeIn');
		return false;
	});
	$('#pop_info_block .msrp_link').click(function() {

		attr = $(this).attr('data-name');
		attr_title = $(this).attr('data-title');
		if (typeof attr !== typeof undefined && attr !== false) {
			$('#pop_msrp_data_name').val(attr);
		}
		if (typeof attr_title !== typeof undefined && attr_title !== false) {
			old_title = $('#pop_order_form title_h2').html();
			$('#pop_msrp_form .title_h2').html('<span class="small">'+attr_title+'</span>');
		}
		attr = $(this).attr('data-id');
		if (typeof attr !== typeof undefined && attr !== false) {
			$('#pop_msrp_data_id').val(attr);
		}
		$("#pop_msrp_form").addClass('visible');
		$("#pop_msrp_form").animateCss('animated fadeIn');

		$('#pop_info_block').removeClass('visible');
		$('#pop_info_block').animateCss('visible animated fadeOut');

		return false;
	});

	$('.open_hidden_content').click(function() {
		hc = $(this).closest('.hidden_content_target').find('.hidden_container');
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			hc.animate({'height': '43px'}, 700);
		} else {
			$(this).addClass('active');
			hc.animate({'height': hc[0].scrollHeight}, 700);
		}
		return false;
	});

	if ($('div').is('.product_photo_slider')) {
		$('.product_photo_slider').each(function() {
			new Swiper($(this).find('.swiper-container'), {
				paginationClickable: true,
				slidesPerView: 1,
				spaceBetween: 0,
				navigation: {
					nextEl: $(this).find('.swiper-button-next'),
					prevEl: $(this).find('.swiper-button-prev'),
				},
			});
		});
	}



	$('.project a.item_logo').click(function() {
		$("#pop_project_form").find('.project_inner').html($(this).parent().find('.inner').html());
		$("#pop_project_form").addClass('visible');
		$("#pop_project_form").animateCss('animated fadeIn');
		return false;
	});


	$(".close_pop_form").click(function() {
		$('#pop_order_data_name').val('');
		$('#pop_order_data_id').val('');
		$(this).closest('.pop_form').removeClass('visible');
		$(this).closest('.pop_form').animateCss('visible animated fadeOut');
		$('#pop_order_form .title_h2').html(old_title);
		return false;
	});

	$('.sidebar_button').click(function() {
		if ($(this).hasClass('active')) {
			$('.sidebar').animate({'height': '0'}, 700, function() {$('.sidebar_button').removeClass('active')});
		} else {
			$('.sidebar').animate({'height': $('.sidebar')[0].scrollHeight}, 700, function() {$('.sidebar_button').addClass('active')});
		}
		return false;
	});



	$('#select_city').click(function() {
		if ($('#cities_list').hasClass('visible')) {
			$('#cities_list').removeClass('visible');
		} else {
			$('#cities_list').addClass('visible');
		}
		return false;
	});

	$('#cities_list a').click(function() {
		if (!$(this).hasClass('active')) {
			$('#cities_list a.active').removeClass('active');
			$(this).addClass('active');
			phone_format = phone_format_normal($(this).attr('data-phone'));
			$('.city_change_phone_link').html('<a href="tel:'+$(this).attr('data-phone')+'">'+phone_format+'</a>');
			$('.city_change_phone').html(phone_format);
			$('.city_change_city').html($(this).attr('data-name'));
			$('.city_change_address').html($(this).attr('data-address'));
			$.removeCookie('prana_city');
			$.cookie('prana_city', $(this).attr('data-id'), { expires: 7, path: '/' });
			$('#cities_list').removeClass('visible');
			$('#select_city').html($(this).attr('data-name'));
		}
		return false;
	});



    $('#m_montage, #m_clear, #m_floors, #m_doors').change(function() {
	    updM()
	});
    updM()

    function updM(){
        minprice = 14826;
		clear = 9400;
		montage =4582;
		if ($('#m_montage').prop('checked') === false) {
			montage = 0;
		}
		if ($('#m_clear').prop('checked') === false) {
			clear = 0;
		}
		if ($('#m_floors').val()*1 > 4) {

			total = (minprice + clear + montage) * $('#m_floors').val() * $('#m_doors').val();
			$('#m_total_sum').val(total);

            $('#price_nonds').html(total);
			//$('#price_nonds').html(number_format(total, 2, ',', ' '));
		} else {
			$('#price_nonds').html(number_format(0, 2, ',', ' '));
		}
    }
	$('.musoroprovod_scheme:not(.in_catalog) .images, .musoroprovod_scheme:not(.in_catalog) .item_name').mouseover(function() {
		if ($(window).width() > 768) {
			$(this).closest('.item.act').addClass('active');
		}
	}).mouseout(function() {
		$(this).closest('.item.act').removeClass('active');
	});


	$('.musoroprovod_scheme.in_catalog .images').click(function() {
		dd = $(this).closest('.item.act');
		if (!dd.hasClass('active')) {
			$('.item.act.active').removeClass('active');
			dd.addClass('active');
			$('.catalog_items_info .item.active ').removeClass('active');
			$('#cat_item_'+$(this).attr('data-item')).addClass('active');
			$("html, body").animate({
				 scrollTop: ($('#cat_item_'+$(this).attr('data-item')).offset().top-35) + "px"
			  }, {
				duration: 1000,
				 easing: "easeInOutCubic"
			 });
		}
		return false;
	});

	if ($('div').is('#catalog_scheme_inner')) {
		cspt = $('#catalog_scheme_inner').offset().top;
		ciil = $('#catalog_items_info').offset().top+$('#catalog_items_info').height()-$(window).height()-60;
		$(window).scroll(function() {
			var scrollTop = $(window).scrollTop();
			if (scrollTop > cspt) {
				if (scrollTop < ciil) {
					pt = scrollTop-cspt+30;
					$('#catalog_scheme_inner').css('margin-top', pt+'px');
				}
			} else {
				$('#catalog_scheme_inner').css('margin-top', '0px');
			}
		});
	}








	$('.tabs_block .tabs_menu a').click(function() {
		if (!$(this).hasClass('active')) {
			$('.tabs_block .tabs_menu a.active').removeClass('active');
			$(this).addClass('active');
			$('.tabs_block .tabs .tab.active').removeClass('active');
			$($(this).attr('href')).addClass('active');
			if ($(this).attr('data-slider') !== undefined) {
				var sl = eval($(this).attr('data-slider'));
				sl.update();
			}
		}
		return false;
	});



	$('#nav li.for_rich_menu a.arrow').click(function() {
		if ($(window).width() <= 768) {
			rul = $(this).parent().find('.rich_menu');
			if (rul.css('height') == '0px') {
				rul.animate({'height': rul[0].scrollHeight}, 700);
			} else {
				rul.animate({'height': '0px'}, 700);
			}
			return false;
		}
	});


	//Поиск
	$('.search_input').on('keyup click', function() {
		if ($(this).val().length > 2) {
			//Функция, которая отправляет запрос в обработчик
			msg = check_search($(this).val());
			if (msg == false) {
				$('#search_results').html('');
				$('#search_results').removeClass('visible');
			} else {
				$('#search_results').html(msg);
				$('#search_results').addClass('visible');
			}
		} else {
			$('#search_results').html('');
			$('#search_results').removeClass('visible');
		}
	});
	$(document).on('click', function(e){
		if ($('#search_results').hasClass('visible') && !$('#search_results').is(e.target) && $('#search_results').has(e.target).length === 0) {
			$('#search_results').removeClass('visible');
		}
	});

	$(".scb").click(function() {
      	$("html, body").animate({
        	 scrollTop: ($($(this).attr("href")).offset().top-150) + "px"
    	  }, {
         	duration: 1000,
        	 easing: "easeInOutCubic"
     	 });
     	 return false;
	});

	$('#etaj_count, #podezd_count').on('keyup click change', function() {
		up = $(this).closest('form').find('#stvol_count');
		up.val($('#etaj_count').val()*$('#podezd_count').val());
	});

	$('.calculate_montage').on('keyup click change', function() {
		stvol_price = $(this).closest('form').find('select[name="stvol_type"] option:selected').attr('data-price');
		tp = stvol_price*$('input[name="stvol_floors"]').val()*$('input[name="stvol_parads"]').val()+$('input[name="stvol_klapan"]').val()*$('input[name="klapan_price"]').val();
		$('.msoptionsprice-cost').html(tp);
	});


	if ($('div').is('#pop_info_block')) {
		if (sessionStorage["session_pib"] == null || sessionStorage["session_pib"] == 'null' || sessionStorage["session_pib"] == 'undefined') {
			sessionStorage["session_pib"] = '1';
			setTimeout(function() {
				$('#pop_info_block').addClass('visible');
				$('#pop_info_block').animateCss('animated fadeIn');
			}, 6000);
		}
	}




});



$.fn.extend({
    animateCss: function (animationName) {
        var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        $(this).addClass('animated ' + animationName).one(animationEnd, function() {
            $(this).removeClass('animated ' + animationName);
        });
	}
});


/***
number - исходное число
decimals - количество знаков после разделителя
dec_point - символ разделителя
thousands_sep - разделитель тысячных
***/
function number_format(number, decimals, dec_point, thousands_sep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function(n, prec) {
      var k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k)
        .toFixed(prec);
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
    .split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '')
    .length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1)
      .join('0');
  }
  return s.join(dec);
}


function phone_format_normal(phf) {
	return phf.replace(/(.)(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$2 ($3) $4-$5-$6');
}



//Функция отправки запроса поиска в обработчик (заглушка)
function check_search(a) {
	if (a == '111') {
		return '<ul class="inner"><li><a href="#">Пункт с результатом поиска</a></li><li><a href="#">Пункт с результатом поиска</a></li><li><a href="#">Пункт с результатом поиска</a></li><li><a href="#">Пункт с результатом поиска</a></li></ul>';
	} else {
		return false;
	}
}
